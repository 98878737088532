<template>
  <div class="requests">
    <div class="begin">
      <div class="begin pb-12">
        <div class="header">
          <the-header :show-add-button="false" :show-file-button="false" :show-number="false" @searchForItems="searchForItems"/>
        </div>
        <div class="body">
          <div class="header-table flex items-center p-4 bg-white rounded-xl border font-medium text-lg">
            <div class="action w-1/12">
              <checkbox-base :check="allCheck" @click="allCheckChange"/>
            </div>
            <div class="action w-1/3 mr-4">{{ $t('object') }}</div>
            <div class="action w-5/12 mr-4">{{ $t('agentsMenu') }}</div>
            <div class="action w-1/4 mr-4">{{ $t('date') }}</div>
            <div class="action w-1/4 text-center">{{ $t('status') }}</div>
          </div>
          <div class="header-body">
            <div class="loading" :class="{ 'not_empty': loading === false }">
              <loading
                :active="loading"
                :can-cancel="false"
                :on-cancel="onCancel"
                :is-full-page="fullPage"
                background-color="#FFFFFF"
                color="#324971"
                loader="dots"
              ></loading>
            </div>
            <div
              v-if="nothing && !loading"
              class="no-student text-center text-xl text-gray-500 py-32"
            >
              {{ $t('noRequestAdded') }}
            </div>
            <div class="listing">
              <request-all-list
                v-for="(item, index) in sortedArrayRequest"
                :key="index"
                :all-request-list="item"
                :is-check="isAllChecked"
                @isJustChecked="isJustChecked"
                @isJustNotChecked="isJustNotChecked"
                @click="openOneRequestDetails(index)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ONE REQUEST DETAILS POPUP -->
    <one-request-details
      v-if="requestDetails"
      :request-details="oneRequest"
      @closeRequestDetail="closeRequestDetail"
      @closingDetails="closingDetails"
    />

    <request-treatment
      v-if="isRequestTreatment"
      :treated-request-details="oneRequest"
      @openRequestSuccess="openRequestSuccess"
      @closeRequestTreatment="closeRequestTreatment"
    />

    <request-success
      v-if="isRequestSuccess"
      :incident-info="oneRequest"
      @closeSuccess="closeSuccess"
    />
  </div>
</template>

<script>
import TheHeader from '@/components/the-header'
import CheckboxBase from '@/components/helper/add/checkbox-base'
import RequestAllList from '@/components/helper/requests/request-all-list'
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore'
import { firestore } from '@/main'
import OneRequestDetails from '@/components/helper/requests/one-request-details'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import RequestTreatment from '@/components/helper/requests/request-treatment'
import RequestSuccess from '@/components/helper/requests/request-success'
export default {
  name: 'index',
  components: { RequestSuccess, RequestTreatment, Loading, OneRequestDetails, RequestAllList, CheckboxBase, TheHeader },
  data () {
    return {
      isAllChecked: true,
      selectedInMatch: [],
      isNotSelected: [],
      allCheck: true,
      allRequest: [],
      requestDetails: false,
      oneRequest: {},
      loading: true,
      fullPage: false,
      nothing: false,
      searching: '',
      isRequestTreatment: false,
      isRequestSuccess: false
    }
  },
  created () {
    this.getAllRequest()
  },
  computed: {
    sortedArrayRequest: function () {
      let sorted = this.allRequest
      if (this.searching !== '') {
        let authorNameSearchString = this.searching
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.object !== null && (item.object.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    closingDetails (val) {
      this.requestDetails = val
      this.isRequestTreatment = true
    },
    async closeSuccess (val) {
      this.isRequestSuccess = val
      /* this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000) */
    },
    closeRequestTreatment (val) {
      this.isRequestTreatment = val
      this.requestDetails = true
    },
    openRequestSuccess (val) {
      this.isRequestTreatment = val
      this.isRequestSuccess = true
    },
    closeRequestDetail (val) { // CLOSE ONE REQUEST DETAILS
      this.requestDetails = val
    },
    openOneRequestDetails (index) { // OPEN ONE REQUEST DETAILS
      this.requestDetails = true
      this.oneRequest = this.sortedArrayRequest[index]
    },
    getAllRequest () { // GET ALL REQUESTS LIST
      const q = query(collection(firestore, 'requests'),
        orderBy('createdAt', 'desc'))
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          cities.push(doc.data())
          this.allRequest = cities
        })
        if (this.allRequest.length === 0) {
          this.loading = false
          this.nothing = true
        } else {
          this.loading = false
          this.nothing = false
        }
      })
    },
    searchForItems (val) { // GET INPUT SEARCH
      this.searching = val
    },
    allCheckChange () {
      this.isAllChecked = this.allCheck
    },
    truncateString (str, num) {
      if (str.length <= num) {
        return str
      }
      return str.slice(0, num) + '...'
    },
    isJustChecked (answer) {
      this.allCheck = false
      this.selectedInMatch.push(answer)
    },
    isJustNotChecked (answer) {
      for (let i = 0; i < this.selectedInMatch.length; i++) {
        if (this.selectedInMatch[i] === answer) {
          this.selectedInMatch.splice(i, 1)
        }
      }
      this.allCheck = false
      this.isNotSelected.push(answer)
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .action {
    color: $team_blue;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
