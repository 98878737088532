<template>
  <div class="request-all-list">
    <div class="begin mt-1 cursor-pointer">
      <div class="header-table flex items-center p-4 bg-white text-lg rounded-xl shadow border cursor-pointer">
        <div class="action w-1/12">
          <checkbox-base :check="isCheck" :id="position" @click.stop="clickOnCheckbox" />
        </div>
        <div class="action w-1/3 mr-4">{{ allRequestList.object }}</div>
        <div class="action w-5/12 mr-4 flex items-center">
          <div
            v-if="agent.profilePicture !== ''"
            class="picture rounded-full w-12 h-12 bg-no-repeat bg-cover mr-2"
            v-bind:style="{ backgroundImage: 'url(' + agent.profilePicture + ')' }"
          ></div>
          <div
            v-else
            :style="backgroundDefine"
            class="pictures w-12 h-12 text-center bg-gray-500 pt-4 mr-2 rounded-full text-sm font-bold text-white"
          >
            {{ getFirstLetter(agent.name) }}
          </div>
          <div class="name ml-2">{{ agent.name }}</div>
        </div>
        <div class="action w-1/4 mr-4">{{ new Date(allRequestList.createdAt).toLocaleDateString('FR', options) }}</div>
        <div class="action w-1/4 text-center">
          <div
            v-if="allRequestList.status === 'declared'"
            class="status-action"
            :style="{ color: '#4225FC' }"
          >
            {{ $t('declaredIncident') }}
          </div>
          <div
            v-if="allRequestList.status === 'treated'"
            class="status-action"
            :style="{ color: '#009900' }"
          >
            {{ $t('treatedIncident') }}
          </div>
          <div
            v-if="allRequestList.status === 'progress'"
            class="status-action"
            :style="{ color: '#FBA705' }"
          >
            {{ $t('goingIncident') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxBase from '@/components/helper/add/checkbox-base'
import { collection, query, where, getDocs } from 'firebase/firestore'
import { firestore } from '@/main'
export default {
  name: 'request-all-list',
  components: { CheckboxBase },
  props: {
    allRequestList: {
      type: Object,
      default: function () {
        return {}
      }
    },
    position: {
      type: Number,
      default: 0
    },
    isCheck: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: { year: 'numeric', month: 'long', day: '2-digit' },
      agent: {},
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    this.getAllAgents()

    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    async getAllAgents () {
      const q = query(collection(firestore, 'agents'),
        where('uid', '==', this.allRequestList.addedBy)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.agent = doc.data()
      })
    },
    clickOnCheckbox () {
      if (this.isCheck) {
        this.$emit('isJustChecked', this.position)
      } else {
        this.$emit('isJustNotChecked', this.position)
      }
      console.log(this.position)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .header-table:hover {
    border-left: 2.5px solid $team_blue;
  }
</style>
