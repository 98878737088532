<template>
  <div class="one-request-details">
    <popup-base>
      <div class="begin m-auto bg-white w-3/4 border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('requestDetails') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="state">
            <span class="state-title">{{ $t('object') + ' : ' }}</span>
            <span class="status pl-4">{{ requestDetails.object }}</span>
          </div><br>
          <div class="state">
            <span class="state-title">{{ $t('description') + ' : ' }}</span>
            <span class="status pl-4">{{ requestDetails.requests_description }}</span>
          </div><br>
          <div class="action flex items-center">
            <div class="state-title">{{ $t('agentsMenu') + ' : ' }}</div>
            <div
              v-if="agent.profilePicture !== ''"
              class="picture rounded-full w-12 h-12 bg-no-repeat bg-cover ml-4 mr-2"
              v-bind:style="{ backgroundImage: 'url(' + agent.profilePicture + ')' }"
            ></div>
            <div
              v-else
              :style="backgroundDefine"
              class="pictures w-12 h-12 text-center bg-gray-500 pt-4 ml-4 mr-2 rounded-full text-sm font-bold text-white"
            >
              {{ getFirstLetter(agent.name) }}
            </div>
            <div class="name ml-2">{{ agent.name }}</div>
          </div><br>
          <div class="state">
            <span class="state-title">{{ $t('sitesMenu') + ' : ' }}</span>
            <span class="status pl-4">{{ requestDetails.siteId ? site.name : '----' }}</span>
          </div><br>
          <div class="state">
            <span class="state-title">{{ $t('joinPieces') + ' : ' }}</span>
            <span v-if="requestDetails.attachment !== ''" class="status pl-4 cursor-pointer" @click="openRequestDocument">{{ 'requete.pdf' }}</span>
            <span v-else class="status pl-4">{{ '----' }}</span>
          </div><br>

          <div v-if="requestDetails.status !== 'treated'" class="footer">
            <div class="header p-8 text-xl flex items-center justify-between">
              <div class="pro font-medium">{{ $t('inProgressTreatment') }}</div>
              <div>
                <Toggle
                  v-model="value"
                  :classes="{
                  container: 'inline-block rounded-full outline-none focus:ring-transparent',
                  handle: 'inline-block bg-white w-5 h-5 top-[27rem] rounded-full absolute transition-all'
                }"
                  @change="changeStatus"
                />
              </div>
            </div>
            <div class="treat-button pt-8">
              <button-base
                :label="$t('treatRequestButton')"
                @click="isCloseDetails"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '@/components/helper/add/popup-base'
import close from '@icon/ic_close.svg'
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { firestore } from '@/main'
import Toggle from '@vueform/toggle'
import ButtonBase from '@/components/helper/add/button-base'
export default {
  name: 'one-request-details',
  components: { Toggle, ButtonBase, PopupBase },
  props: {
    requestDetails: Object
  },
  data () {
    return {
      icons: { close },
      agent: {},
      site: {},
      value: false,
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    // console.log(this.requestDetails)
    this.getAllAgents()
    this.getAllSite()
    this.checkRequestStatus()

    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  methods: {
    checkRequestStatus () { // CHECK IF THE REQUEST STATUS IS IN PROGRESS OR NOT
      if (this.requestDetails.status === 'declared') {
        this.value = false
      } else if (this.requestDetails.status === 'progress') {
        this.value = true
      }
    },
    async changeStatus () { // UPDATE THE INCIDENT STATUS
      // console.log(this.value)
      if (this.value === true) {
        const incidentRef = doc(firestore, 'requests', this.requestDetails.uid)
        await updateDoc(incidentRef, {
          status: 'progress',
          updatedAt: new Date().getTime(),
          updateBy: this.$store.getters.userId
        }).then(response => {
          console.log('status updated successfully ' + response)
          this.closeAdd()
        })
      } else {
        const incidentRef = doc(firestore, 'requests', this.requestDetails.uid)
        await updateDoc(incidentRef, {
          status: 'declared',
          updatedAt: new Date().getTime(),
          updateBy: this.$store.getters.userId
        }).then(response => {
          console.log('status updated successfully ' + response)
          this.closeAdd()
        })
      }
    },
    async getAllSite () { // GET SITE DETAILS
      const q = query(collection(firestore, 'sites'),
        where('uid', '==', this.requestDetails.siteId))

      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data().identification)
        this.site = doc.data().identification
      })
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    openRequestDocument () {
      window.open(this.requestDetails.attachment, '_blank')
    },
    async getAllAgents () { // GET REQUEST AGENT ADDED
      const q = query(collection(firestore, 'agents'),
        where('uid', '==', this.requestDetails.addedBy)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.agent = doc.data()
      })
    },
    isCloseDetails () {
      this.$emit('closingDetails', false)
    },
    closeAdd () {
      this.$emit('closeRequestDetail', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header {
    background: $team_gray_popup;
    color: $team_blue;
  }
  .state-title {
    color: #979797;
  }
  .treat-button::v-deep {
    .button-base {
      text-align: right;
      text-align: -moz-right;
      text-align: -webkit-right;
      .page-button-real {
        border-radius: .25rem;
        width: 50%;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
