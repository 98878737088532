<template>
  <div class="request-success">
    <popup-base>
      <div class="begin bg-white m-auto w-3/4 rounded-lg border p-8">
        <div class="header flex items-center w-full">
          <div class="sous-header flex flex-col justify-center w-full px-20">
            <div class="icon m-auto"><icon :data="icons.success" original width="75" height="75"/></div>
            <div class="title mt-8 font-bold text-3xl text-center">{{ $t('requestTreatedSuccess') }}</div>
            <div class="description mt-8 text-center leading-relaxed">{{ $t('requestMarkedAsTreated') }}</div>
            <div class="ok-button mt-8">
              <button-base
                :label="$t('okButton')"
                :is-loading="loader"
                @click="closeSuccess"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import ButtonBase from '@/components/helper/add/button-base'
import PopupBase from '@/components/helper/add/popup-base'
import success from '@icon/ic_incident_success.svg'
import { collection, doc, getDocs, setDoc } from 'firebase/firestore'
import { firestore } from '@/main'

export default {
  name: 'request-success',
  components: { ButtonBase, PopupBase },
  props: {
    incidentInfo: Object
  },
  data () {
    return {
      icons: { success },
      loader: false,
      allAdmins: []
    }
  },
  created () {
    this.getAllAdmins()
  },
  methods: {
    async getAllAdmins () { // GET ALL ADMINS INFORMATION
      const querySnapshot = await getDocs(collection(firestore, 'admins'))
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAdmins.push(doc.data())
      })
    },
    async closeSuccess () { // SEND ADMINS NOTIFICATIONS FOR TREATED INCIDENT
      this.loader = true
      for (let i = 0; i < this.allAdmins.length; i++) {
        const uniqueID = new Date()
        const myRandom = Math.floor(Math.random() * 1000)
        const newId = uniqueID.getTime() + '' + myRandom
        const newNotification = doc(firestore, 'adminsNotifications', this.allAdmins[i].uid, 'notifications', newId)
        const incidentData = {
          uid: newNotification.id,
          addedBy: this.$store.getters.userId,
          createdAt: new Date().getTime(),
          type: 'treatedRequest',
          incident: this.incidentInfo,
          isRead: false
        }
        await setDoc(newNotification, incidentData)
      }
      this.$emit('closeSuccess', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables";
.svg-fill {
  fill: transparent;
}
.title {
  color: $team_blue;
}
.ok-button::v-deep {
  .button-base {
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    .page-button-real {
      border-radius: .25rem;
      width: 50%;
      h4 {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
