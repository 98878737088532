<template>
  <div class="request-treatment">
    <popup-base>
      <div class="begin bg-white m-auto w-3/4 rounded-lg border">
        <div class="header p-8 text-2xl font-medium">{{ $t('requestTreatment') }}</div>
        <div class="body p-8">
          <div class="type flex border-b pb-6">
            <div class="name">{{ $t('object') }} :</div>
            <div class="response ml-4">{{ treatedRequestDetails.object }}</div>
          </div>
          <div class="type py-6">
            <div class="name">{{ $t('leaveObservation') }} :</div>
            <div class="response mt-2">
              <textarea
                v-model="observation"
                rows="6"
                class="ipt p-4 w-full border rounded resize-none"
              ></textarea>
            </div>
          </div>
          <div class="footer-buttons flex items-center justify-end">
            <div class="back">
              <button-base
                :label="$t('goBackButton')"
                fontcolor="#606060"
                background="#FFFFFF"
                @click="closeTreatment"
              />
            </div>
            <div class="continuous">
              <button-base
                :label="$t('treatRequestButton')"
                :is-loading="loader"
                @click="openSuccess"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import ButtonBase from '@/components/helper/add/button-base'
import PopupBase from '@/components/helper/add/popup-base'
import { doc, updateDoc } from 'firebase/firestore'
import { firestore } from '@/main'
export default {
  name: 'request-treatment',
  components: { ButtonBase, PopupBase },
  props: {
    treatedRequestDetails: Object
  },
  data () {
    return {
      observation: '',
      loader: false
    }
  },
  methods: {
    async openSuccess () {
      this.loader = true
      const incidentRef = doc(firestore, 'requests', this.treatedRequestDetails.uid)
      await updateDoc(incidentRef, {
        status: 'treated',
        observation: this.observation,
        updatedAt: new Date().getTime(),
        updateBy: this.$store.getters.userId
      }).then(response => {
        console.log('incident treated successfully ' + response)
        this.$emit('openRequestSuccess', false)
      })
    },
    closeTreatment () {
      this.$emit('closeRequestTreatment', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables";
.begin {
  border-top: 4px solid $team_blue;
}
.header {
  background: $team_gray_popup;
  color: $team_blue;
}
.name {
  color: #909090;
}
.ipt {
  background: $team_gray_popup;
}
.back::v-deep {
  .button-base {
    .page-button-real {
      border: none;
      h4 {
        font-size: 1.25rem;
      }
    }
  }
}
.continuous::v-deep {
  .button-base {
    .page-button-real {
      border-radius: .25rem;
      width: 18rem;
      h4 {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
